export enum Path {
  Home = '/',
  SignIn = '/{locale}/sign-in',
  SignUp = '/{locale}/sign-up',
  NetworkHome = '/{locale}/{slug}',
  People = '/{locale}/{slug}/people',
  Sources = '/{locale}/{slug}/sources',
  Folder = '/{locale}/{slug}/folder/{id}',
  Space = '/{locale}/{slug}/space/{id}',
  PersonListDefault = '/{locale}/{slug}/person-list/{id}',
  PersonListTable = '/{locale}/{slug}/person-list/{id}/table',
  PersonListBoardDefault = '/{locale}/{slug}/person-list/{id}/board',
  PersonListBoard = '/{locale}/{slug}/person-list/{id}/board/{personalizedFieldId}',
  SettingsMyDetails = '/{locale}/{slug}/settings',
  SettingsNetworkDetails = '/{locale}/{slug}/settings/details',
  SettingsNetworkSyncing = '/{locale}/{slug}/settings/syncing',
  SettingsNetworkMembers = '/{locale}/{slug}/settings/members',
  SettingsNetworkImports = '/{locale}/{slug}/settings/imports',
  PublicPersonTable = '/{locale}/public/people/{publicIdentifier}/table',
  Activity = '/{locale}/{slug}/activity',
}

/* eslint-disable @typescript-eslint/naming-convention*/
// Namespacing of external URLs is extremely useful
export enum ExternalUrl {
  ClearbitLogoAPI = 'https://logo.clearbit.com/',
  Help_CustomerEducationCenter = 'https://huntclub.notion.site/b5556437964c436e8aa5c1f8ee53655e',
  Help_AtlasQuickStart = 'https://huntclub.notion.site/Atlas-Quick-Start-Guide-932951a697864afa9568cb1e41b128ef',
  Help_AtlasQuickStart_AddingCustomFields = 'https://huntclub.notion.site/Atlas-Quick-Start-Guide-932951a697864afa9568cb1e41b128ef#:~:text=Organizing%20Your%20List-,Custom%20Fields,-To%20add%20a',
  Help_AtlasQuickStart_AcceptingInvites = 'https://huntclub.notion.site/Atlas-Quick-Start-Guide-932951a697864afa9568cb1e41b128ef#:~:text=1.-,Accepting%20Invitation%20%2B%20Setting%20Up%20Your%20Account,-You%20should%20have',
  Help_UsingTheChromeExtension = 'https://huntclub.notion.site/Using-the-Atlas-Chrome-Extension-c1d7ce16742547b78ba32ca4dc5d12f1',
}
